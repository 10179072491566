import React, { Component } from 'react';
import { Navbar, Nav, Container, Row, Col, NavDropdown, Button} from 'react-bootstrap';
//import {faUser} from '@fortawesome/free-solid-svg-icons';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisualFeedback, FeedbackCtrl } from '../libs/components/Components.js';
import { BookReaderV2} from './BookReader.js';
import { DashboardView } from './Dashboard';
import { $glVars } from '../common/common';
import { Options} from '../common/Options';
import { faBook, faEnvelope, faHome, faLanguage,  faPhone, faSearch, faShoppingCart, faSignIn, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckoutOrderView } from './OrderView.js';
import { MyBookCollection } from './BookView.js';
import { SignInView } from './SignInView.js';
import { SearchView } from './SearchView.js';

export class MainView extends Component
{
    static defaultProps = {
        onSignIn: null,
        onSignOut: null
    };

    constructor(props){
        super(props);

        this.onMenuSelect = this.onMenuSelect.bind(this);   
        this.onCloseDlgAbout = this.onCloseDlgAbout.bind(this);
        this.openView = this.openView.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChangeRoute = this.onChangeRoute.bind(this);
        this.onFeedback = this.onFeedback.bind(this);

        this.state = {
            brandName: "", 
            viewClass: null, 
            viewProps: null,
            modalClass: null,
            modalProps: null,
            modalCallback: null,
            showFeedback: null
        };

        this.routeCtrl = new RouteCtrl(); 
        this.feedbackCtrl = new FeedbackCtrl();
    }

    componentDidMount(){
        this.routeCtrl.addObserver("MainView", this.onChangeRoute);
        //this.routeCtrl.goTo();
        this.feedbackCtrl.addObserver("MainView", this.onFeedback);
        $glVars.i18n.setLang($glVars.cookies.getLang("fr"));     
        $glVars.routeCtrl = RouteCtrl.instance;

        $glVars.webApi.getAvailableProducts((result) => {
            $glVars.data.availableProducts = result.data;
        });
    }

    componentWillUnmount(){
        this.routeCtrl.removeObserver("MainView");
        this.feedbackCtrl.removeObserver("MainView");
    }

    render() {
        let viewClass = this.state.viewClass || DashboardView;
        let viewProps = this.state.viewProps;

        let main = 
            <div className={`MainView`}>
                <header>
                    <AppNavBar brandName={this.state.brandName} onSelect={this.onMenuSelect} onSearch={this.onSearch}/>
                </header>
                
                {viewClass === SignInView ?
                    <SignInView onSignIn={this.props.onSignIn}/>
                    :
                    viewClass !== null && React.createElement(viewClass, viewProps)
                }
                
                {this.state.modalClass !== null && React.createElement(this.state.modalClass, this.state.modalProps)}
                {this.feedbackCtrl.msg.map((item, index) => {  
                    return (<VisualFeedback key={index} id={index} msg={item.msg} type={item.type} title={item.title} timeout={item.timeout}/>);                                    
                })}
                <Footer/>
                <CookiePolicy/>
            </div>;
        
        return (main);
    }

    onChangeRoute(route){
        if(route.type === "v"){
            this.openView(route.class, route.props);
        }
        else if(route.type === "m"){
            this.openModal(route.class, route.props, route.callback);
        }
        else{
            console.log(route);
        }
    }

    onFeedback(){
        this.forceUpdate();
    }

    onMenuSelect(event){
        if(typeof event.menu === 'undefined'){
            return;
        }

        switch(event.menu){
            case "lang":
                $glVars.i18n.setLang(event.value);
                $glVars.cookies.setLang(event.value);
                this.forceUpdate();
                break;
            case "signout":
                this.props.onSignOut();
                break;
            case "dlgabout":
                this.setState({openDlgAbout: true});
                break;
            case "home":
                this.setState({viewClass: null});
                break;
            default:  
                event.menu();
        }
    }

    onCloseDlgAbout(){
        this.setState({openDlgAbout: false});
    }

    openView(viewClass, viewProps){
        viewProps = viewProps || {};
        this.setState({viewClass: viewClass, viewProps: viewProps, modalClass: null, modalProps: null});
    }

    openModal(modalClass, modalProps, modalCallback){
        modalProps = modalProps || {};
        modalProps.onClose = (result) => this.closeModal(result);
        this.setState({modalClass: modalClass, modalProps: modalProps, modalCallback: modalCallback});
    }

    closeModal(result){
        if(this.state.modalCallback !== null){
            this.state.modalCallback(result);
        }
        this.setState({modalClass: null, modalProps: null}, () => window.history.back());
    }
}

class AppNavBar extends Component {
    static defaultProps = {
        onSelect: null,
        brandName: "",
        onSearch: null
    };

    constructor(props){
        super(props);

        this.onSearch = this.onSearch.bind(this);
    }

    render() {     
        let placeOrder = <Nav.Link href="#placeOrder"><FontAwesomeIcon icon={faPhone} title={$glVars.i18n.tags.placeAnOrder}/>{` ${$glVars.i18n.tags.placeAnOrder}`}</Nav.Link>;
        let lang = 
        <NavDropdown title={<span><FontAwesomeIcon icon={faLanguage} title={$glVars.i18n.tags.chooseYourLanguage}/>{` ${$glVars.i18n.tags[$glVars.i18n.lang]}`}</span>} id="language">
            <NavDropdown.Item onClick={() => this.props.onSelect({menu:"lang", value: 'en'})}>English</NavDropdown.Item>
            <NavDropdown.Item onClick={() => this.props.onSelect({menu:"lang", value: 'fr'})}>Français</NavDropdown.Item>
            <NavDropdown.Item onClick={() => this.props.onSelect({menu:"lang", value: 'es'})}>Español</NavDropdown.Item>
        </NavDropdown>;

        let main = 
            $glVars.signedUser !== null ? 
    
                <Navbar id='mainnavbar' fixed="top"  collapseOnSelect style={{zIndex: 50}}  expand="lg"  className='d-print-none pl-2 pr-2' bg='warning' onSelect={this.props.onSelect}>
                    <Container fluid>
                        <Navbar.Brand>{$glVars.i18n.tags.appName}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="menu" />
                        <Navbar.Collapse id="menu">
                            <Nav className="me-auto">
                                <Nav.Link  onClick={() => RouteCtrl.instance.goToMyBookCollection()}><FontAwesomeIcon icon={faHome} title={$glVars.i18n.tags.home}/>{` ${$glVars.i18n.tags.home}`}</Nav.Link>
                                {$glVars.signedUser.isAdmin && <Nav.Link  onClick={() => RouteCtrl.instance.goToSearch()}><FontAwesomeIcon icon={faSearch} title={$glVars.i18n.tags.search}/>{` ${$glVars.i18n.tags.search}`}</Nav.Link>}
                            </Nav>               
                            <Nav className="pull-right">
                                {lang}
                                {placeOrder}
                                <Nav.Link onClick={() => RouteCtrl.instance.goToCheckoutOrder()}>
                                    <FontAwesomeIcon icon={faShoppingCart} title={$glVars.i18n.tags.myCart}/>
                                    {` ${$glVars.i18n.tags.myCart}`}
                                    {$glVars.signedUser.myCart !== null && $glVars.signedUser.myCart.details.length > 0 ? ` (${$glVars.signedUser.myCart.details.length})` : ``}
                                </Nav.Link>
                               
                                <Nav.Link onClick={() => this.props.onSelect({menu:"signout"})}>
                                    <FontAwesomeIcon icon={faSignOutAlt} title={$glVars.i18n.tags.signOut}/>
                                    {` ${$glVars.i18n.tags.signOut}`}
                                </Nav.Link>
                            </Nav>    
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            :

                <Navbar id='mainnavbar' fixed="top"  collapseOnSelect style={{zIndex: 50}}  expand="lg"  className='d-print-none  pl-2 pr-2' bg="warning" onSelect={this.props.onSelect}>
                    <Container fluid>
                        <Navbar.Brand>
                            {$glVars.i18n.tags.appName}
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="menu" />
                        <Navbar.Collapse id="menu">
                            <Nav className="me-auto">
                                <Nav.Link onClick={() => RouteCtrl.instance.goToDashboard()} href="#books"><FontAwesomeIcon icon={faBook} title={$glVars.i18n.tags.availableBooks}/>{` ${$glVars.i18n.tags.availableBooks}`}</Nav.Link>
                            </Nav> 
                            <Nav className="pull-right">
                                {lang}
                                {placeOrder}
                                <Nav.Link onClick={() => RouteCtrl.instance.goToSignIn()}><FontAwesomeIcon icon={faSignIn} title={$glVars.i18n.tags.signIn}/>{` ${$glVars.i18n.tags.signIn}`}</Nav.Link>
                            </Nav>    
                        </Navbar.Collapse>
                    </Container>
                </Navbar>;
        return (main);
    }/* <Nav.Link  href="#profile">
                                    <FontAwesomeIcon icon={faUserCircle} title={`Connecté(e) comme ${$glVars.signedUser.name}`}/>{` My profile`}
                                </Nav.Link>*/

    onSearch(event){
        // on enter
        if (event.key === "Enter") {
            this.props.onSearch(event.target.value);
        }
    }

}

class Footer extends Component{
    render(){
        let main =
        
        <footer className='bg-dark text-white p-3'>
        <Container fluid>
            <Row>
                <Col sm={12} md={6} className='p-3'>
                    <h4 id="placeOrder">{$glVars.i18n.tags.placeAnOrder}</h4>
                    <div className='m-1'><FontAwesomeIcon icon={faEnvelope}/>{` ${$glVars.i18n.tags.email}:`} <a className='text-white' href={`mailto: ${Options.brandEmail}`}>{Options.brandEmail}</a></div>  
                    <div className='m-1'> <FontAwesomeIcon icon={faPhone}/>{` ${$glVars.i18n.tags.phone}: ${Options.brandPhone}  (WhatsApp)`}</div>
                </Col>
                <Col sm={12} md={6} className='p-3'>
                    
                </Col>
            </Row>
            
            <hr/>
            <Row>
                <Col className='text-center'>
                    <p>
                        {$glVars.i18n.tags.copyright}
                        {" "}
                        {new Date().getFullYear()}
                        {" "}
                        {<a className='text-white' href='https://gnosis.ca'>AGIRA-IGAAS CANADA</a>}
                        {" - "}
                        {$glVars.i18n.tags.allRightsReserved}
                    </p>
                    <Button variant='link' className='text-white' onClick={() => RouteCtrl.instance.goToPrivacyPolice()}>{$glVars.i18n.tags.privacyPolice}</Button>
                </Col>
            </Row>
        </Container>
        </footer>;


        return (main);
    }
}

class CookiePolicy extends Component{
    constructor(props){
        super(props);

        this.dismissCookie = this.dismissCookie.bind(this);

        this.state = {show: ($glVars.cookies.getDismissCookiePolicy(0).toString() === '0')} 
    }

    render(){
        if(!this.state.show){
            return null;
        }

        let main =
        <div className='w-100 bg-warning text-dark p-3' style={{position: 'fixed', bottom: 0}}>
            <Row>
                <Col className='col-12 col-sm-9'>{$glVars.i18n.tags.cookieMessage} <Button className='p-0 text-dark' variant='link' onClick={() => RouteCtrl.instance.goToPrivacyPolice()}>{$glVars.i18n.tags.privacyPolice}</Button>.</Col>
                <Col className='col-12 col-sm-3 p-3 d-flex justify-content-center align-items-center'><Button onClick={this.dismissCookie}>{$glVars.i18n.tags.iUnderstand}</Button></Col>
            </Row>
        </div>;

        return (main);
    }

    dismissCookie(){
        $glVars.cookies.setDismissCookiePolicy(1)
        this.setState({show: false});
    }
}

export class RouteCtrl
{
    static instance = null;
   
    constructor(){
         // using this.constructor is useful when using inheritance
        if(this.constructor.instance){
            return this.constructor.instance;
        }

        this.constructor.instance = this;

        this.addObserver = this.addObserver.bind(this);
        this.removeObserver = this.removeObserver.bind(this);
        this.notifyObservers = this.notifyObservers.bind(this);
        this.goToHome = this.goToHome.bind(this);

        // The popstate event is fired each time when the current history entry changes.
        let that = this;
        window.onpopstate = function(event) {that.goTo(); };

        this.routeTable = {
            "db": {proto: "", class: DashboardView, props:{}},
            "si": {proto: "?v=si", class: SignInView, props:{}},
            "br": {proto: "?v=br&productId={productId}", class: BookReaderV2, props: {className: 'BookReaderV2'}},
            "co": {proto: "?v=co", class: CheckoutOrderView, props:{}},
            "mc": {proto: "?v=mc", class: MyBookCollection, props:{}},
            "pp": {proto: "?v=pp", class: PrivacyPoliceView, props:{}},
            "sv": {proto: "?v=sv", class: SearchView, props:{}},
        };

        this.observers = [];
        this.current = null;
    }

    addObserver(id, update){
        if(this.observers.nxGetItem("id", id, null) === null){
            this.observers.push({id:id, update:update});
        }
    }

    removeObserver(id){
        this.observers.nxRemoveItem("id", id);
    }

    notifyObservers(){        
        for(let o of this.observers){
            o.update(this.current);
        }
    }

    replaceRoute(route){
        window.history.replaceState({pathname: route}, "", route); 
    }

    goTo(route, callback){
        route = route || "";
        callback = callback || null;

        // add the route on the historic to Back button browser
       // if(route.length > 0){
           // if(process.env.NODE_ENV === "production"){
                route = Options.homepage() + route;
           // }
            window.history.pushState({pathname: route}, "", route); 
       // }

        this.current = {class: null, props: {}, type: "", callback: null};
        
        if((route.length === 0) || (route === Options.homepage())){
            this.current.id = 'db';
            this.current.class = this.routeTable['db'].class;
            this.current.type = 'v';
            this.current.callback = callback;
        }
        else{
            route = route.split("?").pop();
            let params = route.split("&");
            for(let param of params){
                param = param.split("=");
                let name = param.shift();
                let value = param.shift();
    
                switch(name){
                    case "v": 
                    case "m": 
                        try{
                            this.current.id = value;
                            this.current.class = this.routeTable[value].class;
                            this.current.type = name;
                            this.current.callback = callback;
                        }
                        catch(error){
                            console.log(error);
                        }
                        break;
                    default:
                        this.current.props[name] = value;
                }
            }
        }

        this.current.props = Object.assign(this.current.props, this.routeTable[this.current.id].props);
        
        this.notifyObservers();
    }

    goToHome(){this.goToDashboard(); }
    goToDashboard(){ this.goTo(); }
    goToSignIn(){  this.goTo(this.routeTable.si.proto); }
    goToCheckoutOrder(){  this.goTo(this.routeTable.co.proto); }
    goToMyBookCollection(){  this.goTo(this.routeTable.mc.proto); }
    goToBookReader(productId){  this.goTo(this.routeTable.br.proto.nxPrintf({productId: productId})); }
    goToPrivacyPolice(){  this.goTo(this.routeTable.pp.proto); }
    goToSearch(){  this.goTo(this.routeTable.sv.proto); }
}

export class PrivacyPoliceView extends Component{
    render(){
        let main =
            <div className='p-2 mt-3 container'>
                <h1>{`${$glVars.i18n.tags.privacyPolice}`}</h1>
                <div className='mb-3 text-muted'>{`${$glVars.i18n.tags.lastUpdate}: 2024-08-16`}</div>

                <div dangerouslySetInnerHTML={{__html: $glVars.i18n.tags.privacyPoliceContent}}></div>
            </div>;

        return main;
    }
}