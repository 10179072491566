import React, { Component } from 'react';
import {  Container, Nav, NavDropdown, Navbar} from 'react-bootstrap';
import { FeedbackCtrl, Loading } from '../libs/components/Components.js';
import { $glVars } from '../common/common';
import { RouteCtrl } from './MainView.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronLeft, faExpand, faFont, faListOl, faPalette, faYinYang } from '@fortawesome/free-solid-svg-icons';
import { Options } from '../common/Options.js';
import Utils from '../libs/utils/Utils.js';
/*import {faSearch, faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';*/

export class BookReaderV2 extends Component
{
    static defaultProps = {
        productId: 0,
    };

    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
        this.getData = this.getData.bind(this);
        this.getDataResult = this.getDataResult.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.onWindowMessage = this.onWindowMessage.bind(this);
        this.onIframeLoad = this.onIframeLoad.bind(this);
        this.setLastPlayback = this.setLastPlayback.bind(this);
        this.setEditorsNotes = this.setEditorsNotes.bind(this);

        this.state = {
            data: "", 
            showMenu: false, 
            options: {
                background: 'background-creme',
                fontSize: 'font-size-medium'
            }, 
            tableContents: []
        };
        
        this.iframeRef = React.createRef();
        this.navbarRef = React.createRef();
        this.loadingRef = React.createRef();
        this.mainNavbar = null;
        this.mainFooter = null;
    }

    componentDidMount(){
        this.getData();    
        this.setMainWindow();
        this.setIframeDimensions();

        window.addEventListener("resize", this.onWindowResize);
        window.addEventListener('message', this.onWindowMessage);
    }

    componentWillUnmount(){
        document.body.style.overflow = 'auto';

        this.mainNavbar.classList.remove('hide');
        this.mainFooter.classList.remove('hide');

        window.removeEventListener("resize", this.onWindowResize);
        window.removeEventListener("message", this.onWindowMessage);
    }

    onWindowMessage(event){
        // Message received from child
        switch(event.data.type){
            case 'ready':
                this.onIframeLoad();
                break;
            default:
                break;
                // nothing
        }
    }

    setMainWindow(){
        document.body.style.overflow = 'hidden';
        
        this.mainNavbar = document.querySelector('header');
        this.mainFooter = document.querySelector('footer');

        this.mainNavbar.classList.add('hide');
        this.mainFooter.classList.add('hide');


        this.navbarRef.current.classList.add("hide");
    }

    onIframeLoad(){
        //this.iframeRef.current.contentWindow.addEventListener("click", this.onClick);
        this.iframeRef.current.contentWindow.document.body.firstChild.addEventListener("click", this.onClick);

        this.iframeRef.current.contentWindow.document.body.classList.add(this.state.options.background);
        this.iframeRef.current.contentWindow.document.body.classList.add(this.state.options.fontSize);

        this.setTableContents();
       // this.setImages();
        this.setEditorsNotes();

        this.iframeRef.current.contentWindow.onscroll = this.setLastPlayback;
        let playback = $glVars.cookies.getLastPlayback($glVars.signedUser.userId, this.props.productId, 0);
        this.iframeRef.current.contentWindow.scrollTo(0, playback);
    }

    setIframeDimensions(){
        this.iframeRef.current.width = window.innerWidth;
        this.iframeRef.current.height = window.innerHeight;
    }

    onWindowResize(){
        this.setIframeDimensions();
    }

    getData(){
        $glVars.webApi.getMyProduct(this.props.productId, this.getDataResult);
    }

    getDataResult(result){
        if(result.data === null){
            FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, $glVars.i18n.tags.noBookFound);
            $glVars.routeCtrl.goToMyBookCollection();
            return;
        }

        let head = this.iframeRef.current.contentWindow.document.head;
        while (head.firstChild) {
            head.removeChild(head.lastChild);
        }
            
        let el = document.createElement("link");
        el.setAttribute('rel', 'stylesheet')
        el.setAttribute('href', `${Options.getDataURL()}common/ebook.css` )
        head.appendChild(el);

        el = document.createElement("script");
        el.setAttribute('src', `${Options.getDataURL()}/common/ebook.js` )
        head.appendChild(el);

        let ebook = result.data.productList[0].productDetail;
        ebook = ebook.replace(/__common__\//g, `${Options.getDataURL()}/common/`);

        // remove mouse right click 
        this.iframeRef.current.contentWindow.document.body.oncontextmenu = function(e){
            return ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false);
        }

        // safari does not accept click event on window, so we add here a root tag to patch it
        this.iframeRef.current.contentWindow.document.body.innerHTML = `<div>${ebook}</div>`;

        this.setState({data: ebook});
    }

    setTableContents(){
        let chapters = this.iframeRef.current.contentWindow.document.querySelectorAll('.headers_chapitre,.headers_chapter');

        let tableContents = [];
        for(let item of chapters){
            tableContents.push(
                {
                    text: item.innerText, 
                    onClick: () => {
                        this.iframeRef.current.contentWindow.scrollTo(0, item.offsetTop);
                    }
                });
        }

        this.setState({tableContents: tableContents});
    }

    /*setImages(){
        let imgList = this.iframeRef.current.contentWindow.document.querySelectorAll('img');

        for(let item of imgList){
            item.setAttribute('src', item.getAttribute('src').replace(/__common__\//g, `${Options.getDataURL()}/common/`));
        }
    }*/

    setLastPlayback(event){
        $glVars.cookies.setLastPlayback($glVars.signedUser.userId, this.props.productId, this.iframeRef.current.contentWindow.scrollY);
    }

    setEditorsNotes(){
        let itemList = this.iframeRef.current.contentWindow.document.querySelectorAll('._idFootnoteLink');

        for(let item of itemList){
            item.addEventListener('click', (event) => {
                event.preventDefault();

                let hash = item.hash.substr(1, item.hash.length - 1); // '#footnote-000' to footnote-000
                let el = this.iframeRef.current.contentWindow.document.getElementById(hash);
                
                if(el){
                    let pos = el.getBoundingClientRect();
                    this.iframeRef.current.contentWindow.scrollTo(0, this.iframeRef.current.contentWindow.scrollY + pos.y);
                }
            })
        }
    }

    render() {        
        let main = 
            <div className={`BookReaderV2`}>
                <Navbar ref={this.navbarRef} bg="dark" variant="dark">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="menu" />
                        <Navbar.Collapse id="second-navbar-nav">
                            <Nav className="w-100" style={{justifyContent: 'space-around'}}>
                                <Nav.Link  onClick={() => RouteCtrl.instance.goToMyBookCollection()} title={$glVars.i18n.tags.back}><FontAwesomeIcon icon={faChevronLeft} /></Nav.Link>
                                <Nav.Link><FontAwesomeIcon icon={faExpand}  onClick={() => this.setOptions('fullscreen')} title={$glVars.i18n.tags.fullScreen}/></Nav.Link>
                                <NavDropdown title={<FontAwesomeIcon icon={faListOl} />} id="dropdownTableContent">
                                    {this.state.tableContents.map((item, index) => {  
                                        return (<NavDropdown.Item key={index} onClick={item.onClick}>{item.text}</NavDropdown.Item>);
                                    })}
                                </NavDropdown>
                                
                                <Nav.Link  onClick={() => this.setOptions('fontSize')} title={$glVars.i18n.tags.fontSize}><FontAwesomeIcon icon={faFont}/></Nav.Link>
                                <Nav.Link onClick={() => this.setOptions('background')} title={$glVars.i18n.tags.backgroundColor}><FontAwesomeIcon icon={faPalette} /></Nav.Link>
                            </Nav>  
                        </Navbar.Collapse>  
                    </Container>
                </Navbar>
                <iframe ref={this.iframeRef} title="Book Reader" ></iframe>

                <Loading ref={this.loadingRef}><FontAwesomeIcon icon={faYinYang} spin/></Loading>
            </div>;
//<Nav.Link><FontAwesomeIcon icon={faSearch} /></Nav.Link>
        return (main);
    }

    onClick(event){
        if(this.navbarRef.current.classList.contains('show')){
            this.navbarRef.current.classList.remove("show");
            this.navbarRef.current.classList.add("hide");
        }
        else{
            this.navbarRef.current.classList.remove("hide");
            this.navbarRef.current.classList.add("show");
        }

        this.iframeRef.current.contentWindow.postMessage({action: 'setCSS', value: this.state.options.background}, "*");
    }

    setOptions(name){
        let options = this.state.options;
        let bgOptions = ['background-white', 'background-creme', 'background-dark'];
        let fontSizeOptions = ['font-size-small', 'font-size-medium', 'font-size-large', 'font-size-extra-large'];

        if(name === 'background'){            
            let current = bgOptions.indexOf(options.background);
            options.background = (bgOptions[++current] ? bgOptions[current] : bgOptions[0]);
        }
        else if(name === 'fullscreen'){
            Utils.toggleFullScreen();
        }
        else if(name === 'fontSize'){
            let current = fontSizeOptions.indexOf(options.fontSize);
            options.fontSize = (fontSizeOptions[++current] ? fontSizeOptions[current] : fontSizeOptions[0]);
        }

        let that = this;
        this.setState({options: options}, () => {
            for(let item of bgOptions){
                that.iframeRef.current.contentWindow.document.body.classList.remove(item);
            }
            that.iframeRef.current.contentWindow.document.body.classList.add(that.state.options.background);

            for(let item of fontSizeOptions){
                that.iframeRef.current.contentWindow.document.body.classList.remove(item);
            }
            that.iframeRef.current.contentWindow.document.body.classList.add(that.state.options.fontSize);
        });
    }
}
