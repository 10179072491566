import React, { Component } from 'react';
import { Form, FormGroup, FormControl, Button, FormLabel} from 'react-bootstrap';
import { FeedbackCtrl, InputEmail } from '../libs/components/Components.js';
import { $glVars } from '../common/common';

export class SignInView extends Component {
    static defaultProps = {
        onSignIn: null
    };
        
    constructor(props){
        super(props);
                
        this.onChangeView = this.onChangeView.bind(this);
        this.onTokenValidation = this.onTokenValidation.bind(this);
        
        this.state = {view: '0'};
    }

    componentDidMount(){
        let token = $glVars.urlParams.token || "";

        if(token.length > 0){
            $glVars.webApi.validateToken(token, this.onTokenValidation);
        }
    }

    onTokenValidation(result){
        if(result.data.state.toString() === '1'){
            $glVars.feedback.showInfo($glVars.i18n.tags.signIn, $glVars.i18n.tags.emailConfirmed, 3); 
        }
        else if(result.data.state.toString() === '3'){
            this.setState({view: '3'})
        }
    }

    render() { 
        let main =  
            <div className="sign-in-view">
                {this.state.view === '0' && <SignInForm onSignIn={this.props.onSignIn} onChangeView={this.onChangeView} />}
                {this.state.view === '1' && <CreateNewAccountForm onCancel={() => this.onChangeView('0')}/>}
                {this.state.view === '2' && <RecoverPasswordForm onCancel={() => this.onChangeView('0')}/>}
                {this.state.view === '3' && <RecoverPasswordForm onCancel={() => this.onChangeView('0')} step={2}/>}
            </div>;
                    
        return (main);
    }

    onChangeView(view){
        this.setState({view: view});
    }
};

class SignInForm extends Component {
    static defaultProps = {
        onSignIn: null,
        onChangeView: null
    };
        
    constructor(props){
        super(props);
        
        this.onSignIn = this.onSignIn.bind(this);
        this.onSignInResult = this.onSignInResult.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        
        this.state = {data: {login: "", password: ""}, showPassword: false};
    }
    
    onKeyDown(event) {
        if (event.which === 13 /* Enter */) {
            this.onSignIn();
            event.preventDefault();
        }
    }
    
    render() { 
        let main =  
            <div className='w-100'>
                <Form onKeyDown={this.onKeyDown}>
                    <div className='mb-3 font-weight-bold'>{$glVars.i18n.tags.alreadyHaveAccount}</div>
                    <FormGroup className='mb-3' controlId="user">
                        <FormControl type="text" name="login" required={true} value={this.state.data.login} placeholder={$glVars.i18n.tags.login} onChange={this.onFormChange}/>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId="password">
                        <FormControl  className='mb-3' type={(this.state.showPassword ? 'text' : 'password')} required={true} name="password" placeholder={$glVars.i18n.tags.password} value={this.state.data.password} onChange={this.onFormChange} aria-describedby="showPassword"/>
                        <Form.Check type="checkbox" id="showPassword" label={this.state.showPassword ? $glVars.i18n.tags.hidePassword : $glVars.i18n.tags.showPassword}  onClick={() => {this.setState({showPassword: !this.state.showPassword})}}/>
                    </FormGroup>
                    <div className="d-grid gap-2 mb-3">
                        <Button  type="button" size="lg" variant="primary" onClick={this.onSignIn}>{$glVars.i18n.tags.signIn}</Button>
                    </div>
                </Form>
                <Button onClick={() => this.props.onChangeView("2")} variant='link' className='fs-6 text-white text-left'>{$glVars.i18n.tags.haveForgottenPassword}</Button>                    
                <hr/>
                <div className='d-flex align-items-center'>
                    <span  className='fs-6 pe-2'>{$glVars.i18n.tags.firstTimeHere}</span>
                    <Button variant='link'  className=' p-0 m-0 text-white fs-6' onClick={() => this.props.onChangeView("1")}>{$glVars.i18n.tags.createMyAccount}</Button>
                </div>
                
            </div>;
                
        return (main);
    }
    
    onSignIn(){
        $glVars.webApi.signIn(this.state.data.login, this.state.data.password, this.onSignInResult);
    }

    onSignInResult(result){
        
        if(result.signedIn){
            this.props.onSignIn(result.data);
            return;
        }

        if(!result.success){
            $glVars.feedback.showError($glVars.i18n.tags.signIn, `${$glVars.i18n.tags.errorOccurred}  \n\n ${$glVars.i18n.tags.details}: ${result.msg}`);
            return;
        }
        
        if((result.data.userId > 0) && (result.data.accountState.toString() === '3')){
            $glVars.feedback.showWarning($glVars.i18n.tags.signIn, $glVars.i18n.tags.confirmEmail); 
        }
        else if(result.data.accountState.toString() === '2'){
            $glVars.feedback.showError($glVars.i18n.tags.signIn, $glVars.i18n.tags.accountSuspended); 
        }
        else if(result.data.accountState.toString() === '0'){
            $glVars.feedback.showError($glVars.i18n.tags.signIn, $glVars.i18n.tags.accountDisabled); 
        }
        else{
            $glVars.feedback.showError($glVars.i18n.tags.signIn, $glVars.i18n.tags.msgSignInFailed); 
        }
    }
    
    onFormChange(event){
        let data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({data: data});
    }
};

class CreateNewAccountForm extends Component {
    static defaultProps = {
        onCancel: null
    };
        
    constructor(props){
        super(props);
        
        this.onCreateAccountResult = this.onCreateAccountResult.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        
        this.state = {data: {fullName: "", email: "", password: "", city: "", country: ""}, formValidated: false};

        this.formRef = React.createRef();
    }
      
    render() { 
        let main =  
            <div className='w-100'>
                <Form noValidate validated={this.state.formValidated} ref={this.formRef}>
                    <div className='text-white mb-3 font-weight-bold'>{$glVars.i18n.tags.createMyAccount}</div>
                    <FormGroup className='mb-3' controlId="fullName">
                        <FormLabel>{$glVars.i18n.tags.fullName}</FormLabel>
                        <FormControl type="text" name="fullName" required={true} value={this.state.data.fullName} onChange={this.onFormChange}/>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId="email">
                        <FormLabel>{$glVars.i18n.tags.email}</FormLabel>
                        <InputEmail name="email" required={true} value={this.state.data.email} onChange={this.onFormChange}/>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId="city">
                        <FormLabel>{$glVars.i18n.tags.city}</FormLabel>
                        <FormControl type="text" name="city" required={true} value={this.state.data.city} onChange={this.onFormChange}/>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId="country">
                        <FormLabel>{$glVars.i18n.tags.country}</FormLabel>
                        <FormControl type="text" name="country" required={true} value={this.state.data.country} onChange={this.onFormChange}/>
                    </FormGroup>
                    <FormGroup className='mb-3' controlId="password">
                        <FormLabel>{$glVars.i18n.tags.password}</FormLabel>
                        <FormControl type="password" required={true} name="password" placeholder={$glVars.i18n.tags.password} onChange={this.onFormChange}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+\-]).{8,12}$"/>
                        <Form.Text className='text-white' id="passwordHelpBlock" >{$glVars.i18n.tags.passwordGuideline}</Form.Text>
                        
                    </FormGroup>
                    <div className="d-flex gap-2 mb-3 flex-wrap flex-sm-nowrap">
                        <Button type="button" size="lg" variant="secondary" className='w-100' onClick={this.props.onCancel}>{$glVars.i18n.tags.cancel}</Button>
                        <Button type="button" size="lg" variant="primary" className='w-100' onClick={this.onSubmit}>{$glVars.i18n.tags.createMyAccount}</Button>
                    </div>
                </Form>
            </div>;
                    
        return (main);
    }
    
    onSubmit(){
        if(this.formRef.current.checkValidity() === false) {
            this.setState({formValidated: true});            
        }
        else{
            this.setState({formValidated: true}, this.onSave);
        }
    }

    onSave(){
        let data = {};
        data = Object.assign(data, this.state.data);
        data.lang = $glVars.i18n.lang;

        $glVars.webApi.createAccount(data, this.onCreateAccountResult);
    }

    onCreateAccountResult(result){
        switch(result.data.state.toString()){
            case '0':
                // account create now go to your email and confirm your accoutn. check spam box
                FeedbackCtrl.instance.showWarning($glVars.i18n.tags.appName, $glVars.i18n.tags.confirmEmail);
                this.props.onCancel();
                break;
            case '1':
                // email alread exist
                FeedbackCtrl.instance.showWarning($glVars.i18n.tags.appName, $glVars.i18n.tags.emailAlreadyExist);
                
                break;
            default:
                // unknow;
                FeedbackCtrl.instance.showWarning($glVars.i18n.tags.appName, 'unknow');
        }
        
    }
    
    onFormChange(event){
        let data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({data: data});
    }
};

class RecoverPasswordForm extends Component {
    static defaultProps = {
        onCancel: null,
        step: 1
    };
        
    constructor(props){
        super(props);
        
        this.onSetNewPasswordResult = this.onSetNewPasswordResult.bind(this);
        this.onRecoverPasswordResult = this.onRecoverPasswordResult.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        
        this.state = {data: {email: "", password: ''}, formValidated: false};

        this.formRef = React.createRef();
    }
      
    render() { 
        let main =  
            this.props.step === 1 ?
                <div className='w-100'>
                    <Form noValidate validated={this.state.formValidated} ref={this.formRef}>
                        <div className='text-white mb-3 font-weight-bold'>{$glVars.i18n.tags.passwordResetRequest}</div>
                        <FormGroup className='mb-3' controlId="email">
                            <FormLabel>{$glVars.i18n.tags.email}</FormLabel>
                            <InputEmail name="email" required={true} value={this.state.data.email} onChange={this.onFormChange}/>
                        </FormGroup>
                        <div className="d-flex gap-2 mb-3 flex-wrap flex-sm-nowrap">
                            <Button type="button"  variant="secondary" className='w-100' onClick={this.props.onCancel}>{$glVars.i18n.tags.cancel}</Button>
                            <Button type="button"  variant="primary" className='w-100' onClick={this.onSubmit}>{$glVars.i18n.tags.request}</Button>
                        </div>
                    </Form>
                </div>
            :
                <div className='w-100'>
                    <Form noValidate validated={this.state.formValidated} ref={this.formRef}>
                        <div className='text-white mb-3 font-weight-bold'>{$glVars.i18n.tags.setMyNewPassword}</div>
                        <FormGroup className='mb-3' controlId="password">
                            <FormLabel>{$glVars.i18n.tags.password}</FormLabel>
                            <FormControl type="password" required={true} name="password" placeholder={$glVars.i18n.tags.password} onChange={this.onFormChange}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+\-]).{8,12}$"/>
                            <Form.Text className='text-white' id="passwordHelpBlock" >{$glVars.i18n.tags.passwordGuideline}</Form.Text>
                        </FormGroup>
                        <div className="d-flex gap-2 mb-3 flex-wrap flex-sm-nowrap">
                            <Button type="button"  variant="secondary" className='w-100' onClick={this.props.onCancel}>{$glVars.i18n.tags.cancel}</Button>
                            <Button type="button"  variant="primary" className='w-100' onClick={this.onSubmit}>{$glVars.i18n.tags.apply}</Button>
                        </div>
                    </Form>
                </div>;
                    
        return (main);
    }
    
    onSubmit(){
        if(this.formRef.current.checkValidity() === false) {
            this.setState({formValidated: true});            
        }
        else{
            this.setState({formValidated: true}, this.onSave);
        }
    }

    onSave(){
        let data = {};

        if(this.props.step === 1){
            data.email = this.state.data.email;
            data.lang = $glVars.i18n.lang;
            $glVars.webApi.recoverPassword(data, this.onRecoverPasswordResult);
        }
        else if(this.props.step === 2){
            data.password = this.state.data.password;
            data.lang = $glVars.i18n.lang;
            data.token = $glVars.urlParams.token || "";
            $glVars.webApi.setNewPassword(data, this.onSetNewPasswordResult);
        }
    }

    onRecoverPasswordResult(result){
        FeedbackCtrl.instance.showWarning($glVars.i18n.tags.appName, $glVars.i18n.tags.recoverPassword);
        this.props.onCancel();
    }
    
    onSetNewPasswordResult(result){
        if(result.data.state.toString() === '0'){
            FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, $glVars.i18n.tags.invalidToken);
        }
        else if(result.data.state.toString() === '1'){
            FeedbackCtrl.instance.showWarning($glVars.i18n.tags.appName, $glVars.i18n.tags.setPasswordResult);
        }
        else{
            FeedbackCtrl.instance.showError($glVars.i18n.tags.appName, $glVars.i18n.tags.errorOccurred);
        }
        
        this.props.onCancel();
    }

    onFormChange(event){
        let data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({data: data});
    }
};